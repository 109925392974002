export default class BaseModel
{
	[key:string] : any;

	constructor(args:any)
	{
	    //Object.defineProperty(this, "data",  { writable: true, enumerable: false, configurable: true });

	    this.initialize();
	    this.populate(args);		
	}

	populate(args:any)
	{
	    for (const key in args)
	    {
	        this[key] = args[key];	
	    }
	}

	initialize()
	{         
	}

	toJson()
	{
	    const data : any = {};

	    for (const key in this)
	    {
	        data[key] = this[key];
	    }

	    return data;
	}
}