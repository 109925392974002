import styles from "./styles.module.scss"
import { useContext, useState } from "react"
import { ModalVideo } from "../../ModalVideo"
import { AuthContext } from "../../context/AuthContext"
import { useCore } from "@/core-nextv3/core/core"
import { useSubCore } from "@/hooks/useSubCore"
import { useTranslation } from "next-i18next"
import { getGameUrl } from "@/core-nextv3/ximax2/ximax2.api"
import { THEME_SETTING } from "@/setting/setting"

export const CardLive = ({ game, maintenance }: any) => 
{
    // console.log("game", game)
    const [ gameUrl, setGameUrl ] = useState()

    const { user }                                                                    = useContext(AuthContext)
    const { setAwaitLoadingHTTP, selectedLanguage }                                   = useCore()
    const { setOpenMessageModal, setMessage, setMessageModalIcon, setOpenLoginModal } = useSubCore()
    const { t }                                                                       = useTranslation()

    let newWindow: any

    async function getGameUrlPage(game: any) 
    {
     
        const setting:any = {
            currency : "KRW",
            gameId   : game.id,
            lang     : "kr",
            ...(game?.limit && { limit : game.limit.value }),
            document : { referencePath : user?.referencePath } 
        }
        const result      = await getGameUrl(setting)

        if (result?.status && result?.data?.returnCode === 0)
        {
            return result?.data?.gameUrl
        }
        else 
        {
            const messageModal = t("Cassino em manutenção")
            setMessage(messageModal)
            setMessageModalIcon("warning")
            setOpenMessageModal(true)
        }
    }

    const openWindow = () => 
    {
        newWindow = window.open("about:blank", "_blank");
    }

    async function openGame(game: string) 
    {
        const allowCasino = (user as any)?.allowCasino

        if (allowCasino !== false) 
        {

            if (THEME_SETTING.openExternalsInNewTab)
            {
                const gameUrl = await getGameUrlPage(game)

                // window.open(gameUrl, "_blank");
                // return

               
                newWindow.location.href =  gameUrl;
                return
            }

            setAwaitLoadingHTTP(true)
          
            
            setGameUrl(gameUrl)
            setAwaitLoadingHTTP(false)
        }
        else 
        {
            setMessage(t("Usuário não possui permissão para realizar apostas em casino"))
            setOpenMessageModal(true)
        }
    }

    const handleClick = () => 
    {

        if (maintenance)
        {
            return
        }

        if (!user)
        {
            setOpenLoginModal(true)
        }
        else 
        {
            openWindow(); 
            openGame(game)
        }
    }


    return (
        <>
            <div className={`${styles.cardLive}` + " " + `${maintenance ? "" : styles.hoverEffect}`}>
                <div className={styles.cardLiveBox}>
                    {/* <a href={game.url} target="_blank" rel="noreferrer"> */}
                    {/* <button onClick={()=> openGame(game.id)} target="_blank" rel="noreferrer"> */}
                    <button onClick={()=> handleClick()} target="_blank" rel="noreferrer">
                        {/* <img alt={game.tpName} src={getImage(game)} title={game.tKR} /> */}
                        <img
                            src={
                                game?.image?._url
                                    ? game?.image?._url
                                    : "/assets/img/cassino_placeholder.png"
                            }
                            alt=''
                        />c
                        {/* <div className={styles.cardLiveBoxLabel}>
                            {selectedLanguage === "pt" ? game?.name : game?.title_kr}
                        </div> */}
                    </button>
                    {maintenance && <div className={styles.overlay}><span>점검중</span></div>}
                </div>

                {gameUrl && <ModalVideo setGameUrl={setGameUrl} gameUrl={gameUrl} />}

                
            </div>
        </>
    )
}
