import styles from "./styles.module.scss"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { delItemCart } from "@/core-nextv3/bet/bet.api"
import { useCore } from "@/core-nextv3/core/core"
import { CART_BET_SETTING } from "@/setting/setting"
import { useTranslation } from "next-i18next"
import { useSubCore } from "@/hooks/useSubCore"
import CartModel from "@/model/cart.model"
import { useContext, useEffect, useState } from "react"
import { isAfter } from "date-fns"
import { downDate } from "@/core-nextv3/util/util"
import { useRouter } from "next/router"
import { AuthContext } from "../../context/AuthContext"
import { FirebaseContext } from "@/bet-nextv1/context/FirebaseContext"
import { useRealTime } from "@/core-nextv3/realtime/realtime.api"
import { calculatePrice } from "@/bet-nextv1/utils/SportMarketsParser"
import { betIsLocked } from "@/bet-nextv1/utils/checkBetStatusForLock"

export const BetItemData = ({ item }: any) => 
{
    const { betCart, setBetCart, selectedLanguage, setIsLoadingCart } = useCore()

    const { t } = useTranslation()

    const [ lock, setLock ]                         = useState(false)
    const router                                    = useRouter()
    const { CART_BACKEND }                          = useContext(AuthContext)
    const firebaseApp                               = useContext(FirebaseContext);
    const [ price, setPrice ]                       = useState<any>(item?.bet?.price)
    const [ suspensionReason, setSuspensionReason ] = useState<any>(item?.bet?.suspensionReason);
    
    const {
        setOpenMessageModal,
        setMessage,
        setMessageModalIcon,
        setCartChanges
    } = useSubCore();
    // apenas p/ testes
    // const lock = true;


    useEffect(() =>
    {
        setPrice(item?.bet?.price)
    }, [ item ])

    useRealTime( firebaseApp, item?.bet?.referencePath, async (data: any) => 
    {

        const oldBet = calculatePrice(item?.bet.price.toString(), {
            live    : router.asPath === "/liveSports/" ? true : false,
            virtual : item.game.virtual,
            sport   : item.sport,
        }, item.dividends)
        
        const newBet = calculatePrice(data.price, {
            live    : router.asPath === "/liveSports/" ? true : false,
            virtual : item.game.virtual,
            sport   : item.sport,
        }, item.dividends)

        if (oldBet != newBet)
        {
            let message = t("Dividendos atualizados de <0> para <1>")
            message     = message.replace("<0>", oldBet)

            message = message.replace("<1>", newBet)
            setMessage(message);
            setMessageModalIcon("warning");
            setOpenMessageModal(true);
            setCartChanges(true)
        }

        item.bet.price =  data.price;
        setPrice(data.price)
       

        if (betIsLocked(data.suspensionReason))
        {
            const cart   = new CartModel(betCart)
            const result = cart.delItem(data)
            setBetCart(cart)

                
        }
        
    }
    )

    useEffect(() => 
    {
        const route = router.asPath

        if (item && route === "/sports/") 
        {
            console.warn("asdsadsadsa", item)

            if (isAfter(new Date(), new Date(item?.game?.startDate))) 
            {
                setLock(true)
            }

            downDate(new Date(item?.game?.startDate), () => 
            {
                console.warn("Ativou carrinho", item)
                setLock(true)
            })
        }
        else if (item && route === "/liveSports/") 
        {
            
            // regra de cadeado do livesport
        }
    }, [])

    const formatBetItem = (item: any) => 
    {
        if (item?.market && item?.game) 
        {
            const betName      = item?.name
            const participants = item?.game?.participants

            if (item?.market?.name === "Under/Over") 
            {
                return item?.name
            }
            else if (item?.market?.name === "Double Chance") 
            {
                switch (item?.name) 
                {
                    case "12":
                        return "Vitória ou empate do time da casa"
                    case "1X":
                        return "Vitória do time da casa ou vitória do time de fora"
                    case "X2":
                        return "Vitória ou empate do time de fora"
                    default:
                        return "error"
                }
            }
            else 
            {
                if (item?.name === "X") 
                {
                    return "Empate"
                }

                for (let i = 0; i < participants?.length; i++) 
                {
                    if (
                        participants[i]?.id === item?.participant?.id
                    ) 
                    {
                        return selectedLanguage === "pt"
                            ? `${participants[i]?.name_br}`
                            : `${participants[i]?.name_ko}`
                    }
                }

                return betName
            }
        }
        else if (item.game) 
        {
            return item?.name
        }
        else 
        {
            console.error("Error item: ", item)
            return "Error"
        }
    }

    const removeCartItem = async (item: any) => 
    {
        if (CART_BACKEND) 
        {
            //Carrinho BackEnd
            setIsLoadingCart(true)
            const result = await delItemCart(CART_BET_SETTING.merge({ data : item }))
            setIsLoadingCart(false)

            if (result.status) 
            {
                setBetCart(result.data)
            }
        }
        else 
        {
            //Carrinho FrontEnd
            const cart = new CartModel(betCart);
            cart.delItem(item)
            setBetCart(cart)

        }
    }

    const parseLine = (line: any) => 
    {
    // Find the index of the "(" character
        const indexOfParenthesis = line.indexOf("(")

        // Check if the "(" character is found
        if (indexOfParenthesis !== -1) 
        {
            // Remove all characters after "(" using substring
            const newString = line.substring(0, indexOfParenthesis - 1)

            return newString
        }
        else 
        {
            return line
        }
    }

    const checkLock = (item: any) => 
    {
        if (router.asPath === "/sports/") 
        {
            if (lock) 
            {
                return true
            }

            if (item?.game?.status?.value === 1 || item?.game?.status?.value === 9) 
            {
                return false
            }
            else 
            {
                return true
            }

            // return false
        }

        return false
    }

    return (
        <div className={styles.betItemData}>
            <div className={styles.content}>
                <div className={styles.leagueData}>
                    <span className={styles.league}>{item?.league?.name}</span>
                    <div className={styles.buttonContainer}>
                        <AiOutlineCloseCircle
                            onClick={() => 
                            {
                                removeCartItem(item)
                            }}
                            className={styles.closeButton}
                        />
                    </div>
                </div>
                {item?.game?.participants && (
                    <span className={styles.game}>
                        {item?.game?.participants
                            ?.map((item: any) =>
                                selectedLanguage === "pt" ? item?.name_br : item?.name_ko
                            )
                            .join(" X ")}
                    </span>
                )}
                <div className={styles.marketData}>
                    {
                        <span className={styles.market}>
                            {selectedLanguage === "pt"
                                ? item?.market?.name_br
                                : item?.market?.name_ko.replaceAll("_", " ")}{" "}
                            {item?.bet?.line && `(${parseLine(item?.bet?.line)})`} -{" "}
                            {item?.filteredName && t(item?.filteredName)}{" "}
                            {t(formatBetItem(item))}
                        </span>
                    }
                    <span className={styles.price}>
                        {/* {Intl.NumberFormat("en", {
                            minimumFractionDigits : 2,
                            maximumFractionDigits : 2,
                            roundingMode          : "floor",
                        }).format(item.price)} */}
                        {calculatePrice(price, {
                            live    : router.asPath === "/liveSports/" ? true : false,
                            virtual : item.game.virtual,
                            sport   : item.sport,
                        }, item.dividends)}
                    </span>
                </div>
            </div>
            <div className={styles.divisor} />
            {checkLock(item) && (
                <div className={styles.lockItem}>
                    <div className={styles.image}>
                        <img alt='' src='/assets/img/Lock1.png' />
                    </div>
                </div>
            )}
        </div>
    )
}
