import { ACCOUNT_SETTING, CONFIG_SETTING, SIZE_BANNERS_SETTING } from "@/setting/setting";
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { getAccount } from "@/core-nextv3/account/account.api";
import { getDocument } from "@/core-nextv3/document/document.api";
import { calls, revalidateResults } from "@/core-nextv3/util/call.api";

export default async function withHeader(propsFunc: any)
{
    let props = {};

    const [
        account,
        sizeBanners,
        siteSettings
    ] = await calls(
        getAccount(ACCOUNT_SETTING),
        getDocument(SIZE_BANNERS_SETTING),
        getDocument(CONFIG_SETTING)
    )

    const revalidateResult = revalidateResults([
        account,
        sizeBanners,
        siteSettings
    ])

    if (revalidateResult?.revalidate) 
    {


        const props = {
            revalidateWithHeader : { 
                props : {
                    revalidateResult
                },
                notFound : true,
            }
        }

        const pageStaticProps = await propsFunc(props);
        
        pageStaticProps.props = { 	
            ...props, 
        };

        return pageStaticProps
    }


    props = {
        account                          : account?.data,
        sizeBanners                      : sizeBanners?.data,
        FIRST_DEPOSIT_BONUS_CASINO_TABLE : siteSettings.data["1stCasinoRecharge"] || {},
        DEPOSIT_LEVELS                   : siteSettings.data.deposits || {},
        WITHDRAW_LEVELS                  : siteSettings.data.withdrawals || {},
        PAYBACK_LEVELS                   : siteSettings.data.payback || {},
        LIVE_LEVEL                       : siteSettings.data.live || {},
        DEPOSIT_LEVELS_BONUS             : siteSettings.data.bonusRules || {},
        GENERAL_CONFIGS                  : siteSettings.data.general || {},
        FIRST_DEPOSIT_BONUS_SPORT_TABLE  : siteSettings.data.firstSportRecharge || {},
        BOSCORE_CONFIG                   : siteSettings.data.boscore || {},
        DEPOSIT_BONUS_SPORT_TABLE        : siteSettings.data.sportRefill || {},
        LOTUS_CONFIG                     : siteSettings.data.lotus,
        DEPOSIT_BONUS_CASINO_TABLE       : siteSettings.data.casinoRecharge || {},
        SPORT_LEVEL                      : siteSettings.data.sport || {},
    };

    const pageStaticProps = await propsFunc(props);
    pageStaticProps.props = { 	
        ...props, 
        ...pageStaticProps.props, 
        ...(await serverSideTranslations(pageStaticProps?.props?.locale || pageStaticProps?.locale || "kr"))
    };

    return pageStaticProps;
}
