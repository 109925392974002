import Types from "../type"
import { ISetting } from "../util/setting"
import { call } from "../util/call.api"

const verifyLoginAuth = async (setting: ISetting) => 
{
    const result = await call(Types.VERIFY_LOGIN_AUTH_SERVER, setting)

    return result
}

const isLoggedAuth = async (setting: ISetting) => 
{
    const result = await call(Types.IS_LOGGED_AUTH_SERVER, setting)

    return result
}

const addUserAuth = async (setting: ISetting) => 
{
    const result = await call(Types.ADD_USER_AUTH_SERVER, setting)

    return result
}

const setUserAuth = async (setting: ISetting) => 
{
    const result = await call(Types.SET_USER_AUHT_SERVER, setting)

    return result
}

const setUserAddress = async (setting: ISetting) => 
{
    const result = await call(Types.ADD_DOCUMENT_SERVER, setting)

    return result
}

const setUserCreditCard = async (setting: ISetting) => 
{
    const result = await call(Types.ADD_DOCUMENT_SERVER, setting)

    return result
}

const loginAuth = async (setting: ISetting) => 
{
    const result = await call(Types.LOGIN_AUTH_SERVER, setting)

    return result
}

const logoutAuth = async (setting: ISetting) => 
{
    const result = await call(Types.LOGOUT_AUTH_SERVER, setting)

    return result
}

const loginTokenAuth = async (setting: any) => 
{
    const result = await call(Types.ADD_USER_WITH_TOKEN_SERVER, setting)

    return result
}

const getTokenLoginAuth = async (setting: any) => 
{
    const result = await call(Types.GET_TOKEN_LOGIN_AUTH_SERVER, setting)

    return result
}

const recoveryPasswordAuth = async (setting: ISetting) => 
{
    const result = await call(Types.RECOVERY_PASSWORD_SERVER, setting)

    return result
}

const unblockedUser = async (setting: ISetting) => 
{
    const result = await call(Types.UNBLOCKED, setting)

    return result
}

const getAuthToken = async (setting: ISetting) => 
{
    const result = await call(Types.GET_AUTH_TOKEN_SERVER, setting)

    return result
}

export {
    loginAuth,
    logoutAuth,
    recoveryPasswordAuth,
    getTokenLoginAuth,
    loginTokenAuth,
    verifyLoginAuth,
    setUserAuth,
    setUserAddress,
    setUserCreditCard,
    addUserAuth,
    isLoggedAuth,
    getAuthToken,
    unblockedUser,
}
