import Types from "../type";

import { ISetting } from "../util/setting";
import { call } from "../util/call.api";

const collectionDocument = async (setting: ISetting) => 
{
    const result = await call(Types.COLLECTION_DOCUMENT_SERVER, setting);

    return result;
}

const countDocument = async (setting: ISetting) => 
{
    const result = await call(Types.COUNT_DOCUMENT_SERVER, setting);

    return result;
}

const countOwnerDocument = async (setting: ISetting) => 
{
    const result = await call(Types.COUNT_OWNER_DOCUMENT_SERVER, setting);

    return result;
}

const collectionOwnerDocument = async (setting: ISetting) => 
{
    const result = await call(Types.COLLECTION_OWNER_DOCUMENT_SERVER, setting);

    return result;
}

const getDocument = async (setting: ISetting) => 
{
    const result = await call(Types.GET_DOCUMENT_SERVER, setting);

    return result;
}

const addDocument = async (setting: ISetting) => 
{
    const result = await call(Types.ADD_DOCUMENT_SERVER, setting);

    return result;
}

const setDocument = async (setting: ISetting) => 
{
    const result = await call(Types.SET_DOCUMENT_SERVER, setting);

    return result;
}

const incrementDocument = async (setting: ISetting) => 
{
    const result = await call(Types.INCREMENT_DOCUMENT_SERVER, setting);

    return result;
}

const cancelDocument = async (setting: ISetting) => 
{
    const result = await call(Types.DELETE_DOCUMENT_SERVER, setting);

    return result;
}

const setViews = async (setting: ISetting) => 
{
    const result = await call(Types.SET_VIEWS_SERVER, setting);

    return result;
}

const flatDocument = async (setting: ISetting) => 
{
    const result = await call(Types.FLAT_DOCUMENT_SERVER, setting);

    return result;
}

const clearCache = async (setting: ISetting) => 
{
    const result = await call(Types.CLEAR_CACHE_SERVER, setting);

    return result;
}

const exportDocument = async (setting: ISetting) =>
{
    const result = await call(Types.EXPORT_DOCUMENT_SERVER, setting);

    return result;
}

export {
    collectionDocument,
    getDocument,
    addDocument,
    setViews,
    setDocument,
    cancelDocument,
    incrementDocument,
    collectionOwnerDocument,
    countDocument,
    flatDocument,
    clearCache,
    countOwnerDocument,
    exportDocument,
}