export interface ISetting {
  merge: any;
  status: boolean;
  accid: string;
  appid: string;
  colid: string;
  toJson: () => any;
}

const setting = (data: any): ISetting => 
{
    const obj = Object.assign(
        {
            merge : function (data: any) 
            {
                return setting(Object.assign({}, this, data));
            },
        },
        data,
    );

    Object.defineProperty(obj, "merge", {
        writable     : true,
        enumerable   : false,
        configurable : true,
    });

    Object.defineProperty(obj, "toJson", {
        value : function () 
        {
            return  Object.entries(this).reduce((result, [ key, value ]) => 
            {
                if (typeof value !== "function") 
                {
                    result[key] = value;
                }

                return result;
            }, {} as Record<string, unknown>)
        },
        writable     : true,
        enumerable   : false,
        configurable : true,
    });

    return obj;
};

export { setting };