import { buscaCep2 } from "./util";

const dateMask = (date: any) => 
{
    return new Intl.DateTimeFormat("pt-BR").format(new Date(date));
};

const currencyMask = (number: any) => 
{
    return new Intl.NumberFormat("pt-BR", {
        style    : "currency",
        currency : "BRL",
    }).format(number).replace(/\u00A0/g, " ");
};

const cpfMask = (
    v: string,
    setValue:any,
    clearErrors:any
) => 
{
    if (v.length >= 15) 
    {
        return setValue("cpf", v.slice(0, 14));
    }

    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos

    if (v.length < 15) 
    {
        clearErrors("cpf");
    }

    return setValue("cpf", v);
};

const cnpjMask = (
    v: string,
    setValue: any,
    clearErrors: any) => 
{
    if (v.length >= 18) 
    {
        return setValue("cnpj", v.slice(0, 18));
    }

    v = v.replace(/\D+/g, ""); // não deixa ser digitado nenhuma letra
    v = v.replace(/(\d{2})(\d)/, "$1.$2"); // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1/$2");// captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
    v = v.replace(/(\d{4})(\d)/, "$1-$2");
    v = v.replace(/(-\d{2})\d+?$/, "$1"); // captura os dois últimos 2 números, com um - antes dos dois números

    if (v.length < 18) 
    {
        clearErrors("cnpj");
    }

    return setValue("cnpj", v);
}

export const cpfCnpjMask = (value: string,
    setValue: any,
    clearErrors: any) => 
{

    let v = value;

    if (v.length <= 15) 
    {
        v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
        v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
        //de novo (para o segundo bloco de números)
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
    }
    else if (v.length > 15 && v.length < 18) 
    {
        v = v.replace(/\D+/g, ""); // não deixa ser digitado nenhuma letra
        v = v.replace(/(\d{2})(\d)/, "$1.$2"); // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1/$2");// captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
        v = v.replace(/(\d{4})(\d)/, "$1-$2");
        v = v.replace(/(-\d{2})\d+?$/, "$1"); // captura os dois últimos 2 números, com um - antes dos dois números
    }
    else if (v.length >= 18) 
    {
        return setValue("cpfcnpj", v.slice(0, 18));
    }

    if (v.length < 15) 
    {
        clearErrors("cpfcnpj");
    }

    return setValue("cpfcnpj", v);
}

export const cpfCnpjMaskV2 = (value: string,
    clearErrors: any) => 
{

    let v = value;

    if (v.length <= 15) 
    {
        v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
        v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
        //de novo (para o segundo bloco de números)
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
    }
    else if (v.length > 15 && v.length < 18) 
    {
        v = v.replace(/\D+/g, ""); // não deixa ser digitado nenhuma letra
        v = v.replace(/(\d{2})(\d)/, "$1.$2"); // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1/$2");// captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
        v = v.replace(/(\d{4})(\d)/, "$1-$2");
        v = v.replace(/(-\d{2})\d+?$/, "$1"); // captura os dois últimos 2 números, com um - antes dos dois números
    }
    else if (v.length >= 18) 
    {
        return v.slice(0, 18);
    }

    if (v.length < 15) 
    {
        clearErrors("cpfcnpj");
    }

    return v;
}


export const cpfCnpjMask2 = (value: string,
    setValue: any,
    clearErrors: any) => 
{

    let v = value;

    if (v.length <= 15) 
    {
        v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
        v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
        //de novo (para o segundo bloco de números)
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
    }
    else if (v.length > 15 && v.length < 18) 
    {
        v = v.replace(/\D+/g, ""); // não deixa ser digitado nenhuma letra
        v = v.replace(/(\d{2})(\d)/, "$1.$2"); // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
        v = v.replace(/(\d{3})(\d)/, "$1.$2");
        v = v.replace(/(\d{3})(\d)/, "$1/$2");// captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
        v = v.replace(/(\d{4})(\d)/, "$1-$2");
        v = v.replace(/(-\d{2})\d+?$/, "$1"); // captura os dois últimos 2 números, com um - antes dos dois números
    }
    else if (v.length >= 18) 
    {
        return setValue(v.slice(0, 18));
    }

    if (v.length < 15) 
    {
        clearErrors("cpfcnpj");
    }

    return setValue(v);
}

const cepMask = async (
    cep: string,
    setValue: any
) => 
{
    if (cep.length >= 9) 
    {
        const address: any = await buscaCep2(cep.slice(0, 9));

        setValue("city", address?.localidade);
        setValue("district", address?.bairro);
        setValue("state", address?.uf);
        setValue("street", address?.logradouro);

        return setValue("zipcode", cep.slice(0, 9));
    }
    else if (cep.length == 8) 
    {
        const address: any = await buscaCep2(cep.slice(0, 9));

        setValue("city", address?.localidade);
        setValue("district", address?.bairro);
        setValue("state", address?.uf);
        setValue("street", address?.logradouro);
    }

    cep = cep.replace(/\D/g, "").replace(/^(\d{5})(\d{3})+?$/, "$1-$2");

    setValue("zipcode", cep);
};

const phoneMask = async (
    phone: string,
    setValue: any
) => 
{
    if (phone.length > 15) 
    {
        return setValue("phone", phone.slice(0, 15));
    }

    phone = phone
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{5})(\d)/, "$1-$2");

    setValue("phone", phone);
};


const phoneMaskv2 = async (
    phone: string,
    nameKey: string,
    setValue:any
) => 
{
    if (phone.length > 15) 
    {
        return setValue(nameKey, phone.slice(0, 15));
    }

    phone = phone
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "($1) $2")
        .replace(/(\d{4})(\d)/, "$1-$2");

    setValue(nameKey, phone);
};

const phonesMaskVOne = (
    phone: string,
    clearErrors: any) => 
{

    let p = phone;

    if (p.length <= 14) 
    {
        p = p.replace(/\D/g, "")
        p = p.replace(/(\d{2})(\d)/, "($1) $2")
        p = p.replace(/(\d{4})(\d)/, "$1-$2");
        return p;

    }
    else if (p.length === 15) 
    {
        p = p.replace(/\D/g, "")
        p = p.replace(/(\d{2})(\d)/, "($1) $2")
        p = p.replace(/(\d{5})(\d)/, "$1-$2");
        return p;

    }
    else if (p.length > 15) 
    {
        return p.slice(0, 15);
    }

    // setValue("phone", phone);
    if (p.length < 15) 
    {
        clearErrors("phone");
    }
};

const phoneMaskClean = (phone: string) => 
{
    if (phone.length > 15) 
    {
        return phone.slice(0, 15);
    }

    phone = phone.replace(/\D/g, "");
    phone = phone.replace(/(\d{2})(\d)/, "($1) $2");
    phone = phone.replace(/(\d{4})(\d{4})/, "$1-$2");
    return phone;
};

const cnpjMaskClean = (cnpj: string) => 
{
    if (cnpj.length >= 16) 
    {
        return cnpj.slice(0, 18);
    }

    return cnpj
        .replace(/\D/g, "") // somente números
        .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};

export { currencyMask, dateMask, cpfMask, cepMask, phoneMask, phoneMaskv2, phonesMaskVOne, cnpjMask, phoneMaskClean, cnpjMaskClean };
