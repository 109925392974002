import Types from "../type";
import { call } from "../util/call.api";

const checkCnpj = async (cnpj:any) => 
{
    const result = await call(Types.CNPJWS_SERVER, cnpj);
    return result;
}

export { checkCnpj };
