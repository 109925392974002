import { create } from "zustand"

type UseCoreProps = {
	awaitLoadingHTTP: boolean
	isLoadingCart: boolean // SE O CARRINHO FOI CARREGADO
	isLoadingUser: boolean // SE O USUARIO  FOI CARREGADO
	user: any
	cart: any
	betCart: any
	colorTable: any
	favorites: any
	cnpjWs: any
	pouchDb: any
	setColorTable: any
	setCart: any
	setBetCart: any
	setUser: any
	setCnpjWs: any
	setPouchDb: any
	setIsLoadingCart: any
	setIsLoadingUser: any
	setAwaitLoadingHTTP: any
	selectedLanguage: any
	setSelectedLanguage: any
	isOpenCart: boolean
	setIsOpenCart: any
	isOpenMenu: boolean
	setIsOpenMenu: any
	isOpenSearch: boolean
	setIsOpenSearch: any
	languages: any
	setLanguages: any
	setFavorites: any
	translate: any
}

export const useCore = create<UseCoreProps>((set: any, get: any) => ({
    awaitLoadingHTTP    : false,
    isLoadingCart       : false,
    isLoadingUser       : false,
    isOpenCart          : false,
    isOpenMenu          : false,
    isOpenSearch   			  : false,
    user                : null,
    cart                : null,
    betCart             : null,
    cnpjWs              : null,
    pouchDb             : null,
    colorTable          : null,
    favorites           : [],
    freeShipping        : 0,
    selectedLanguage    : null,
    languages           : {},
    setFavorites        : (favorites: any) => set(() => ({ favorites : favorites })),
    setCart             : (cart: any) => set(() => ({ cart : cart })),
    setBetCart          : (betCart: any) => set(() => ({ betCart : betCart })),
    setUser             : (user: any) => set(() => ({ user : user })),
    setColorTable       : (colorTable: any) => set(() => ({ colorTable : colorTable })),
    setCnpjWs           : (value: any) => set(() => ({ cnpjWs : value })),
    setPouchDb          : (value: any) => set(() => ({ pouchDb : value })),
    setIsLoadingCart    : (value: any) => set(() => ({ isLoadingCart : value })),
    setIsLoadingUser    : (value: any) => set(() => ({ isLoadingUser : value })),
    setIsOpenCart       : (value: any) => set(() => ({ isOpenCart : value })),
    setIsOpenMenu       : (value: any) => set(() => ({ isOpenMenu : value })),
    setIsOpenSearch     : (value: any) => set(() => ({ isOpenSearch : value })),
    setAwaitLoadingHTTP : (value: any) => set(() => ({ awaitLoadingHTTP : value })),
    setSelectedLanguage : (value: any) => set(() => ({ selectedLanguage : value })),
    setLanguages        : (value: any) => 
    {
        const newCollection: any = {}

        let auxObj: any = {}

        for (let index = 0; index < value?.length; index++) 
        {
            const obj = Object.keys(value[index])
            const key = value[index]["pt"]

            for (let indexKey = 0; indexKey < obj.length; indexKey++) 
            {
                if (obj[indexKey] !== "referencePath") 
                {
                    auxObj[`${obj[indexKey]}`] = value[index][`${obj[indexKey]}`]
                }
            }

            newCollection[`${key}`] = auxObj
            auxObj = {}
        }

        set({ languages : newCollection })
    },
    translate : (value: string) => 
    {
        const languages = get().languages
        const selectedLanguage = get().selectedLanguage

        return languages[value] && languages[value][selectedLanguage]
            ? languages[value][selectedLanguage]
            : value
    },
}))
