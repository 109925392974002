/* PIPPA */
import BaseModel from "./base.model";

export class BaseCollection extends Array
{
    constructor(items?:any)
    {
        super();
        
        if (items instanceof Array)
        {
            this.populate(items);	
        }
    }

    getMapsModel()
    {
        return {
            model    		: BaseModel,
            collection : BaseCollection,
        }
    }

    populate(items:any)
    {
        for (const item of items)
        {
            this.set(item);
        }
    }

    toJson()
    {
        const items : any = [];

        for (const key in this)
        {
            if (typeof this[key].toJson == "function")
            {
                items.push(this[key].toJson());
            }
            else
            {
                items.push(this[key]);
            }
        }

        return items;
    }

    add(item: any)
    {
        this.push(this.parseMaps(item));
    }

    set(item:any):void
    {
        let item2 = this.getItem(item);   
        
        if (item2)
        {
            item2.populate(item);
        }
        else
        {            
            item2 = this.parseMaps(item);
            this.push(item2);
        }
    }

    setItems(items:any)
    {
        for (const item of items)
        {
            this.set(item);
        }

        return this;
    }

    delItem(item:any):void
    {
        this.del(item);
    }

    delItems(items:any):void
    {
        for (const key in items)
        {
            const item = items[key];
            this._del(item);
        }
    }

    del(item:any):boolean
    {
        const value = this._del(item);
        
        return value;
    }

    /* PARA QUANDO UTILIZA O DELITEMS NÃO DISPARA VARIOS DISPATCHNEXT */
    _del(item:any):boolean
    {
        const position = this.findPosition(item);

        if (position > -1)
        {
            this.splice(position, 1);			

            return true;
        }
        else
        {
            return false;
        }
    }

    clone()
    {
        const maps  = this.getMapsModel();
        const where = new maps.collection;

        for (const item of this)
        {
            where.push(item);
        }

        return where;
    }

    parseMaps(item:any):any
    {
        const maps = this.getMapsModel();
        const model : any = maps.model;
        const list  : any = maps.collection;
        let   data  : any;	
		
        if (item instanceof model)
        {
            data = item;
        }
        else if (item instanceof list)
        {
            data = item;
        }
        else
        {
            data = new model(item);
        }

        return data;
    }

    findPosition(item:any):number
    {
        let i = 0;

        for (const key in this)
        {
            const item2 : any = this[key];

            if (item.id == item2.id)
            {
                return i;
            }

            i++;
        }

        return -1;
    }

    getItem(item:any):any
    {
        for (const key in this)
        {
            const item2 : any = this[key];

            if (item.id === undefined)
            {
                console.error("id não definido na API");
            }

            if (item.id == item2.id)
            {
                return item2;
            }
        }
    }
}